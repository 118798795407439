import React, { lazy, Suspense } from "react";
import { InLineLoader } from "./components/InlineLoader/InlineLoader";
import { Route, Routes } from "react-router-dom";
import * as navigations from "./utils/navigation";
import Layout from "./layouts/Layout";
import LayoutLogin from "./layouts/LayoutLogin";
import LayoutAdmin from "./layouts/LayoutAdmin";
import LayoutPayments from "./layouts/LayoutPayments";
// import DecisionSearch from "./pages/search_decision/Search_decision";
// import DecisionSearchBar from "./pages/search_decision/Search_decision";

const Search = lazy(() => import("./pages/search/search"));
const SearchDetail = lazy(() => import("./pages/search/searchDetail"));
const SearchDetails = lazy(() => import("./pages/search/searchDetails"));
const Cart = lazy(() => import("./pages/cart/cart"));
const Login = lazy(() => import("./pages/auth/login/login"));
const Logout = lazy(() => import("./pages/logout/logout"));
const Register = lazy(() => import("./pages/auth/register/register"));
const VerificationOtp = lazy(() => import("./pages/auth/verification-otp/verification-otp"));
const ForgotPassword = lazy(() => import("./pages/auth/forgot-password/forgot-password"));
const ResetPassword = lazy(() => import("./pages/auth/reset-password/reset-password"));
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const Settings = lazy(() => import("./pages/settings/settings"));
const SettingsProfile = lazy(() => import("./pages/settings/profile/profile"));
const SettingsPassword = lazy(() => import("./pages/settings/password/password"));
const Payments = lazy(() => import("./pages/payments/payments"));
const ApiKey = lazy(() => import("./pages/api-key/api-key"));
const Log = lazy(() => import("./pages/log/log"));
const ALERTE = lazy(() => import("./pages/alerte/alerte"));
const TEST = lazy(() => import("./pages/search_decision/main/main"));
const DecisionConsult = lazy(() => import("./pages/search_decision/decision/decision"));
const ChatIA = lazy(() => import("./pages/search_decision/ChatIA/chatIA"));


const LISTENOTIFICATION = lazy(() => import("./pages/alerte/listeNotification"));
const HISTORIQUEMODIFICATION = lazy(() => import("./pages/alerte/historiqueModification"));
const FACTURATIONS = lazy(() => import("./pages/facturation/facturations"));
const SEARCH = lazy(() => import("./pages/search/search"));
const ORGANISATIONS = lazy(() => import("./pages/organisation/organisations"));
const FORMALITE = lazy(() => import("./pages/formalite/personne_morale"));
const FORMALITE_PHYSIQUE = lazy(() => import("./pages/formalite/personne_physique"));
const INDEXFORMALITE = lazy(() => import("./pages/formalite/indexFormalite"));
const CHOISFORMALITE = lazy(() => import("./pages/formalite/ChoixTypeEntreprise"));
const JURIDICTION = lazy(() => import("./pages/formalite/juridiction"));
const ANNONCELEGALE = lazy(() => import("./pages/formalite/annonce"));
const SUIVIE = lazy(() => import("./pages/formalite/suivie"));
const SearchSurvey = lazy(() => import("./pages/search/SearchSurvey"));


const LSearchRoutes = () => {
  return (
    <Suspense fallback={<InLineLoader />}>
      <Routes>
        <Route exact={true} path={navigations.SEARCH} element={<Layout><Search /></Layout>} />
        <Route exact={true} path={navigations.DECISIONSEARCHS} element={<LayoutAdmin><TEST /></LayoutAdmin>} />
        <Route exact={true} path={navigations.DECISIONS_CONSULT} element={<LayoutAdmin><DecisionConsult /></LayoutAdmin>} />
        <Route exact={true} path={navigations.DECISIONS_CONSULT_CHAT} element={<LayoutAdmin><ChatIA /></LayoutAdmin>} />



        <Route exact={true} path={navigations.SEARCH_DETAIL} element={<Layout><SearchDetail /></Layout>} />
        <Route exact={true} path={navigations.CART} element={<Layout><Cart /></Layout>} />
        <Route exact={true} path={navigations.LOGIN} element={<LayoutLogin><Login /> </LayoutLogin>} />
        <Route exact={true} path={navigations.REGISTER} element={<LayoutLogin><Register /> </LayoutLogin>} />
        <Route exact={true} path={navigations.VERIFICATION_OTP} element={<LayoutLogin><VerificationOtp /> </LayoutLogin>} />
        <Route exact={true} path={navigations.FORGOT_PASSWORD} element={<LayoutLogin><ForgotPassword /> </LayoutLogin>} />
        <Route exact={true} path={navigations.RESET_PASSWORD} element={<LayoutLogin><ResetPassword /></LayoutLogin>} />
        <Route exact={true} path={navigations.DASHBOARD} element={<LayoutAdmin><Dashboard /> </LayoutAdmin>} />
        <Route exact={true} path={navigations.SEARCHS} element={<LayoutAdmin><SEARCH /></LayoutAdmin>} />
        <Route exact={true} path={navigations.SEARCH_SURVEILLE} element={<LayoutAdmin><SearchSurvey /></LayoutAdmin>} />
        <Route exact={true} path={navigations.SETTINGS} element={<LayoutAdmin><Settings /> </LayoutAdmin>} />
        <Route exact={true} path={navigations.SEARCH_DETAILS} element={<LayoutAdmin><SearchDetails /></LayoutAdmin>} />

        <Route exact={true} path={navigations.PROFILE} element={<LayoutAdmin><SettingsProfile /> </LayoutAdmin>} />
        <Route exact={true} path={navigations.PASSWORD} element={<LayoutAdmin><SettingsPassword /> </LayoutAdmin>} />
        <Route exact={true} path={navigations.LOGOUT} element={<LayoutAdmin><Logout /> </LayoutAdmin>} />
        <Route exact={true} path={navigations.PAYMENTS} element={<LayoutPayments><Payments /> </LayoutPayments>} />
        <Route exact={true} path={navigations.API_KEY} element={<LayoutAdmin><ApiKey /></LayoutAdmin>} />
        <Route exact={true} path={navigations.LOG} element={<LayoutAdmin><Log /></LayoutAdmin>} />
        <Route exact={true} path={navigations.ALERTE} element={<LayoutAdmin><ALERTE /></LayoutAdmin>} />
        <Route exact={true} path={navigations.LISTENOTIFICATION + '/:id'} element={<LayoutAdmin><LISTENOTIFICATION /></LayoutAdmin>} />
        <Route exact={true} path={navigations.HISTORIQUEMODIFICATION + '/:id'} element={<LayoutAdmin><HISTORIQUEMODIFICATION /></LayoutAdmin>} />
        <Route exact={true} path={navigations.FACTURATIONS} element={<LayoutAdmin><FACTURATIONS /></LayoutAdmin>} />
        <Route exact={true} path={navigations.ORGANISATIONS} element={<LayoutAdmin><ORGANISATIONS /></LayoutAdmin>} />
        <Route exact={true} path={navigations.FORMALITE} element={<LayoutAdmin><FORMALITE /></LayoutAdmin>} />
        <Route exact={true} path={navigations.FORMALITE_PHYSIQUE} element={<LayoutAdmin><FORMALITE_PHYSIQUE /></LayoutAdmin>} />
        <Route exact={true} path={navigations.INDEXFORMALITE} element={<LayoutAdmin><INDEXFORMALITE /></LayoutAdmin>} />
        <Route exact={true} path={navigations.ChoixTypeEntreprise} element={<LayoutAdmin><CHOISFORMALITE /></LayoutAdmin>} />
        <Route exact={true} path={navigations.JURIDICTION} element={<LayoutAdmin><JURIDICTION /></LayoutAdmin>} />
        <Route exact={true} path={navigations.ANNONCE} element={<LayoutAdmin><ANNONCELEGALE /></LayoutAdmin>} />
        <Route exact={true} path={navigations.SUIVIE} element={<LayoutAdmin><SUIVIE /></LayoutAdmin>} />

      </Routes>
    </Suspense>


  );
};

export default LSearchRoutes;
