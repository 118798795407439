import React, { useEffect, useState, useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { Modal, ModalBody, ModalHeader, ROLE, SIZE } from "baseui/modal";
import CheckoutForm from "./CheckoutForm";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../store/modal/actions";
import { loadStripe } from "@stripe/stripe-js";
import { useData } from "../../contexts/DataProvider";
import { Api } from "../../api/services/Api";
import Spinner from "../../components/LoadSpinner/Spinner";
import { getLocalState } from "../../utils/localStorage";
import { toast } from "react-toastify";

const STRIPE_PK_LIVE = process.env.REACT_APP_STRIPE_PK_LIVE;
const stripePromise = loadStripe(STRIPE_PK_LIVE);

function StripeModal() {
  const useModal = useSelector((state) => state?.modal);
  const dispatch = useDispatch();
  const [paymentDatas, setPaymentDatas] = useState({
    clientSecret: "",
    response: null,
  });
  const [dataPaiements, setdataPaiements] = useState();
  const { data } = useData();

  useEffect(() => {
    if (!data || !useModal?.data) return;

    const { data: dataPaiement } = data;
    dataPaiement.currency = useModal.data.currency;
    const currentUser = getLocalState("_currentUser");

    setdataPaiements(dataPaiement);

    const getPaymentIntent = async () => {
      try {
        const paymentData = {
          provider: "stripe",
          payment_method: useModal.data.payment_method_id,
          user: currentUser.user_id,
          amount: dataPaiement.amount,
          currency: dataPaiement.currency,
          payment_type: "card",
          description: dataPaiement.description,
        };

        const response = await Api.post(
          "recouv/payment/",
          JSON.stringify(paymentData)
        );
        if (response.data.status === 400 || response.data.status === 401) {
          toast.error("Erreur lors du chargement des données");
        }
       
        setPaymentDatas({
          clientSecret: response.data.client_secret,
          response: response.data,
        });
      } catch (error) {
        console.error("Error creating payment intent:", error);
      }
    };

    getPaymentIntent();
  }, [data, useModal?.data]);

  // console.log("response", paymentDatas.response);

  const options = useMemo(
    () => ({
      appearance: { theme: "flat" },
      locale: "fr",
      clientSecret: paymentDatas.clientSecret,
    }),
    [paymentDatas.clientSecret]
  );

  return (
    <Modal
      onClose={() => dispatch(hideModal())}
      closeable
      isOpen={useModal?.isOpenStripePayment}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>
        <div className="tranch-confirm-header">
          <div className="tranch-confirm-header-box-title">
            <h5 className="tranch-confirm-header-title">Achat de document</h5>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        {paymentDatas.clientSecret ? (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              pays={useModal?.data?.pays}
              nextLink={useModal?.data?.nextLink}
              dataPaiement={dataPaiements}
              paymentData={paymentDatas}
            />
          </Elements>
        ) : (
          <Spinner />
        )}
      </ModalBody>
    </Modal>
  );
}

export default StripeModal;
