import { Link } from "react-router-dom";

 const DropdownMenu = ({ isOpen, items }) => {
  if (!isOpen) return null;
  return (
    <ul className="absolute bg-white shadow-md rounded-md mt-2 w-80 right-0 p-2 ">
      {items.map((item, index) => (
        <li key={index} className="hover:bg-gray-200 text-theme-1 rounded-md p-2 border-b">
          <span className="flex justify-between items-center">
           
          <Link className="block  py-2 font-bold " to={item.link}>
            {item.label}
          </Link>
            {item.icon}
          </span>
          {item.sub_label && (
            <span className="text-sm text-gray-600 font-semibold ">
              {item.sub_label}
            </span>
          )}
        </li>
        
      ))}
    </ul>
  );
};

export  default DropdownMenu;