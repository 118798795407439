import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

function Spinner({ size = 30 }) {
  return (
    <div style={{ textAlign: "center", margin: "10px auto" }}>
      <BeatLoader color="#F57F01" size={size} />
    </div>
  );
}

export default Spinner;

