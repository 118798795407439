import React from "react";
import { FooterWrapper } from "./footer.style";
import { Link } from "react-router-dom";
import "../../App.css";
const Footer = () => {
  return (
    <FooterWrapper>
      <div className="lg-recoov-footer-container">
        <div className="lg-recoov-newsletter ">
          <div className="lg-recoov-newsletter-box-title">
            <h4 className="lg-recoov-newsletter-title">
              Laissez nous un message
            </h4>
          </div>
          {/* <form action="" className="lg-recoov-newsletter-form">
            <div className="lg-recoov-newsletter-form-content"> */}
          {/* <input
                type="text"
                placeholder="Entrez votre e-mail"
                className="lg-recoov-newsletter-form-input"
              /> */}
            <Link to="https://share.hsforms.com/1SXK4l2SzQhO01C8yoFWafA32gu0">
          <button className="text-white px-10 py-3  hover:bg-green-700 bg-green-600 rounded-lg transition duration-300 ease-in-out">
              Contacter
          </button>
            </Link>
          {/* </div> */}
          {/* </form> */}
        </div>
        <div className="lg-recoov-footer-top">
          <div className="lg-recoov-footer-illustration">
            <div className="lg-recoov-footer-logo-box">
              <a
                className="lg-recoov-footer-logo"
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="lg-recoov-footer-logo-image"
                  src="/assets/images/logo-recoov.png"
                  loading="lazy"
                  alt="logo de Datagreffe"
                />
              </a>
            </div>
            <div className="lg-recoov-footer-text-box">
              <p className="lg-recoov-footer-text ">
                DATAGREFFE est une plateforme d'informations légales des
                entreprises en Côte d'Ivoire
              </p>
            </div>

            <div className="lg-recoov-footer-social-box">
              <ul className="lg-recoov-footer-social-list">
                <li>
                  <a href="#">
                    <i className="fab fa-whatsapp fa-2x lg-recoov-footer-social-icon"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-facebook fa-2x lg-recoov-footer-social-icon"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-linkedin fa-2x lg-recoov-footer-social-icon"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="lg-recoov-footer-nav">
            <div className="lg-recoov-footer-nav-item">
              <h4 className="lg-recoov-footer-nav-title">Notre offre</h4>
              <ul className="lg-recoov-footer-nav-list">
                <li className="lg-recoov-footer-nav-list-item">
                  <a className="lg-recoov-footer-nav-list-link" href="#tarif">
                    Tarifs
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="lg-recoov-footer-nav">
            <div className="lg-recoov-footer-nav-item">
              <h4 className="lg-recoov-footer-nav-title">Adresse et contact</h4>
              <ul className="lg-recoov-footer-nav-list">
                <li className="lg-recoov-footer-nav-list-item">
                  <a
                    className="lg-recoov-footer-nav-list-link"
                    href="https://datagreffe.ci/"
                  >
                    Côte d’Ivoire,Abidjan
                  </a>
                </li>
                <li className="lg-recoov-footer-nav-list-item">
                  <Link
                    className="hover:text-orange-500  transition duration-300 ease-in-out"
                    to="mailto:contact@datagreffe.ci"
                  >
                    contact@datagreffe.ci
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-2"></div>
    </FooterWrapper>
  );
};

export default Footer;
