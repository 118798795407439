import * as constants from "./constants";
export const initialState = {
  //search
  searchLoading: false,
  search: [],
  isMakeSearch: false,
  searchError: null,

  //search detail
  searchDetailLoading: false,
  searchDetail: null,
  searchDetailError: null,

  //Decision pour le demandeur
  searchDecisionApplicantsLoading: false,
  searchDecisionApplicants: [],
  searchDecisionApplicantsError: null,

  //Decision pour le defendeur
  searchDecisionDefendantsLoading: false,
  searchDecisionDefendants: [],
  searchDecisionDefendantsError: null,

  //Autre decision pour le demandeur
  searchOthersApplicantsLoading: false,
  searchOthersApplicants: [],
  searchOthersApplicantsError: null,

  //Autre decision pour le defendeur
  searchOthersDefendantsLoading: false,
  searchOthersDefendants: [],
  searchOthersDefendantsError: null,

  //search detail dirigeants
  searchDirigeantsDetailLoading: false,
  searchDirigeantsDetail: [],
  searchDirigeantsDetailError: null,

  //search for chart
  cart: [],
  isLoadingCart: false,
};
const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SEARCH_REQUESTING:
      return {
        ...state,
        searchLoading: true,
      };
      case constants.SEARCH_REQUESTING_ALERTE:
      return {
        ...state,
        searchLoading: true,
      };
    case constants.SEARCH_SUCCESS:
      return {
        ...state,
        searchLoading: false,
        isMakeSearch: true,
        search: action?.payload,
        searchError: null,
      };
    case constants.SEARCH_EMPTY:
      return {
        ...state,
        searchLoading: false,
        isMakeSearch: true,
        search:[],
        searchError: null,
      };
    case constants.SEARCH_FAILED:
      return {
        ...state,
        searchLoading: false,
        isMakeSearch: true,
        search: [],
        searchError: action.error,
      };
    //Detail for search
    case constants.SEARCH_DETAIL_REQUESTING:
      return {
        ...state,
        searchDetailLoading: true,
      };

    case constants.SEARCH_DETAIL_SUCCESS:
      return {
        ...state,
        searchDetailLoading: false,
        searchDetail: action?.payload,
        searchDetailError: null,
      };

    case constants.SEARCH_DETAIL_FAILED:
      return {
        ...state,
        searchDetailLoading: false,
        searchDetail: null,
        searchDetailError: action.error,
      };

    //Decision pour le demandeur
    case constants.SEARCH_DECISION_APPLICANTS_REQUESTING:
    case constants.PAGINATE_SEARCH_DECISION_APPLICANTS_REQUESTING:
      return {
        ...state,
        searchDecisionApplicantsLoading: true,
      };

    case constants.SEARCH_DECISION_APPLICANTS_SUCCESS:
    case constants.PAGINATE_SEARCH_DECISION_APPLICANTS_SUCCESS:
      return {
        ...state,
        searchDecisionApplicantsLoading: false,
        searchDecisionApplicants: action?.payload,
        searchDecisionApplicantsError: null,
      };

    case constants.SEARCH_DECISION_APPLICANTS_FAILED:
    case constants.PAGINATE_SEARCH_DECISION_APPLICANTS_FAILED:
      return {
        ...state,
        searchDecisionApplicantsLoading: false,
        searchDecisionApplicants: [],
        searchDecisionApplicantsError: action.error,
      };

    //Decision pour le defendeur
    case constants.SEARCH_DECISION_DEFENDANTS_REQUESTING:
    case constants.PAGINATE_SEARCH_DECISION_DEFENDANTS_REQUESTING:
      return {
        ...state,
        searchDecisionDefendantsLoading: true,
      };

    case constants.SEARCH_DECISION_DEFENDANTS_SUCCESS:
    case constants.PAGINATE_SEARCH_DECISION_DEFENDANTS_SUCCESS:
      return {
        ...state,
        searchDecisionDefendantsLoading: false,
        searchDecisionDefendants: action?.payload,
        searchDecisionDefendantsError: null,
      };

    case constants.SEARCH_DECISION_DEFENDANTS_FAILED:
    case constants.PAGINATE_SEARCH_DECISION_DEFENDANTS_FAILED:
      return {
        ...state,
        searchDecisionDefendantsLoading: false,
        searchDecisionDefendants: [],
        searchDecisionDefendantsError: action.error,
      };

    //Autre decision pour le demandeur
    case constants.SEARCH_OTHERS_APPLICANTS_REQUESTING:
    case constants.PAGINATE_SEARCH_OTHERS_APPLICANTS_REQUESTING:
      return {
        ...state,
        searchOthersApplicantsLoading: true,
      };

    case constants.SEARCH_OTHERS_APPLICANTS_SUCCESS:
    case constants.PAGINATE_SEARCH_OTHERS_APPLICANTS_SUCCESS:
      return {
        ...state,
        searchOthersApplicantsLoading: false,
        searchOthersApplicants: action?.payload,
        searchOthersApplicantsError: null,
      };

    case constants.SEARCH_OTHERS_APPLICANTS_FAILED:
    case constants.PAGINATE_SEARCH_OTHERS_APPLICANTS_FAILED:
      return {
        ...state,
        searchOthersApplicantsLoading: false,
        searchOthersApplicants: [],
        searchOthersApplicantsError: action.error,
      };

    //Autre Decision pour le defendeur
    case constants.SEARCH_OTHERS_DEFENDANTS_REQUESTING:
    case constants.PAGINATE_SEARCH_OTHERS_DEFENDANTS_REQUESTING:
      return {
        ...state,
        searchOthersDefendantsLoading: true,
      };

    case constants.SEARCH_OTHERS_DEFENDANTS_SUCCESS:
    case constants.PAGINATE_SEARCH_OTHERS_DEFENDANTS_SUCCESS:
      return {
        ...state,
        searchOthersDefendantsLoading: false,
        searchOthersDefendants: action?.payload,
        searchOthersDefendantsError: null,
      };

    case constants.SEARCH_OTHERS_DEFENDANTS_FAILED:
    case constants.PAGINATE_SEARCH_OTHERS_DEFENDANTS_FAILED:
      return {
        ...state,
        searchOthersDefendantsLoading: false,
        searchOthersDefendants: [],
        searchOthersDefendantsError: action.error,
      };

    //Detail for dirigeant search
    case constants.SEARCH_DETAIL_DIRIGEANTS_REQUESTING:
      return {
        ...state,
        searchDirigeantsDetailLoading: true,
      };
    case constants.SEARCH_DETAIL_DIRIGEANTS_SUCCESS:
      return {
        ...state,
        searchDirigeantsDetailLoading: false,
        searchDirigeantsDetail: action?.payload,
        searchDirigeantsDetailError: null,
      };
    case constants.SEARCH_DETAIL_DIRIGEANTS_FAILED:
      return {
        ...state,
        searchDirigeantsDetailLoading: false,
        searchDirigeantsDetail: [],
        searchDirigeantsDetailError: action.error,
      };
    default:
      return state;
  }
};

export default searchReducer;
