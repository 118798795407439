import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Icons from "react-feather";

import { getLocalState } from "../../utils/localStorage";
import { DASHBOARD, LOGIN } from "../../utils/navigation";
import "../../App.css";
import DropdownMenu from "../../components/DropdownMenu/DropdownMenu";

const Navbar = () => {
  const { pathname } = useLocation();
  const currentUser = getLocalState("_currentUser");

  // États pour gérer les dropdowns
  const [isAPIDropdownOpen, setAPIDropdownOpen] = useState(false);
  const [isSitesDropdownOpen, setSitesDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  
  // Données des menus dropdown
  const apiMenuItems = [
    { label: "API Documentation", link: "#" },
    { label: "Developer", link: "#" },
  ];

  const sitesMenuItems = [
    {
      label: "DATAGREFFE JUSTICE",
      sub_label: "Décisions de justice",
      link: "https://devjustice.datagreffe.ci/",
      icon: <Icons.ExternalLink className="w-4 h-4"/>,
    },
    {
      label: "DATAGREFFE ENTREPRISE",
      sub_label: "Données des entreprises",
      link: "https://dev.datagreffe.ci/",
      icon: <Icons.ExternalLink  className="w-4 h-4"/>,

    },
  ];

  return (
    <nav className="bg-white shadow-md fixed top-0 w-full z-50">
      <div className="flex px-4 md:px-6 lg:px-8 justify-between items-center py-3">
        {/* Logo */}
        <Link to="/">
          <img
            className="h-10 w-auto"
            src="/assets/images/logo-recoov.png"
            loading="lazy"
            alt="logo de Datagreffe"
          />
          <p className="text-gray-500 text-sm italic hidden md:inline">
            Informations légales d'entreprises
          </p>
        </Link>

        {/* Formulaire de recherche */}
        {pathname !== "/" && (
          <div className="flex-1 ml-48 mr-20 hidden md:flex">
            <form className="flex w-full space-x-3">
              <input
                type="text"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                placeholder="Rechercher une entreprise..."
              />
              <button
                type="submit"
                className="btn-login-datagreffe text-white rounded-lg hover:bg-orange-600 transition"
              >
                Rechercher
              </button>
            </form>
          </div>
        )}

        {/* Navigation */}
        <div className="hidden md:flex space-x-4 items-center">
          {pathname === "/" && (
            <Link
              className="text-gray-600 text-sm hover:text-orange-600"
              to="mailto:contact@datagreffe.ci"
            >
              contact@datagreffe.ci
            </Link>
          )}

          {/* Dropdown API */}
          <div className="relative">
            <button
              onClick={() => setAPIDropdownOpen(!isAPIDropdownOpen)}
              className="text-gray-700 hover:text-orange-500 focus:outline-none"
            >
              API ▼
            </button>
            <DropdownMenu isOpen={isAPIDropdownOpen} items={apiMenuItems} />
          </div>

          {/* Dropdown Nos Sites */}
          <div className="relative">
            <button
              onClick={() => setSitesDropdownOpen(!isSitesDropdownOpen)}
              className="text-gray-700 hover:text-orange-500 focus:outline-none"
            >
              Nos sites ▼
            </button>
            <DropdownMenu isOpen={isSitesDropdownOpen} items={sitesMenuItems} />
          </div>

          {currentUser ? (
            <Link to={DASHBOARD}>Accès client</Link>
          ) : (
            <Link to={LOGIN}>Connexion</Link>
          )}
        </div>

        {/* Menu Mobile */}
        <div className="md:hidden">
          <button
            onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
            className="text-gray-700 focus:outline-none"
          >
            ☰
          </button>
        </div>
      </div>

      {/* Menu Mobile */}
      <div
        className={`fixed inset-0 bg-white z-50 transform ${
          isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out md:hidden`}
      >
        <div className="p-5 flex flex-col h-full">
          {/* Header du menu avec logo et bouton de fermeture */}
          <div className="flex justify-between items-center">
            {/* Logo */}
            <Link to="/" className="flex items-center">
              <img
                className="h-10 w-auto"
                src="/assets/images/logo-recoov.png"
                loading="lazy"
                alt="logo de Datagreffe"
              />
              <p className="text-gray-500 text-sm italic hidden md:inline ml-2">
                Informations légales d'entreprises
              </p>
            </Link>

            {/* Bouton de fermeture */}
            <button
              onClick={() => setMobileMenuOpen(false)}
              className="text-3xl text-gray-700"
            >
              ✖
            </button>
          </div>

          {/* Formulaire de recherche */}
          {/* {pathname !== "/" && (
      <div className="mt-5">
        <form className="flex w-full space-x-3">
          <input type="text" className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none" placeholder="Rechercher une entreprise..." />
          <button type="submit" className="btn-login-datagreffe text-white rounded-lg hover:bg-orange-600 transition">
            Rechercher
          </button>
        </form>
      </div>
    )} */}

          {/* Liens du menu */}
          <div className="mt-8 space-y-4">
            <Link
              className="block text-gray-700 text-lg hover:text-orange-500"
              to="mailto:contact@datagreffe.ci"
            >
              contact@datagreffe.ci
            </Link>

            {/* Dropdown API */}
            <div>
              <button
                onClick={() => setAPIDropdownOpen(!isAPIDropdownOpen)}
                className="w-full text-left text-gray-700 text-lg hover:text-orange-500"
              >
                API ▼
              </button>
              <ul
                className={`pl-4 transition-all duration-200 ${
                  isAPIDropdownOpen ? "block" : "hidden"
                }`}
              >
                {apiMenuItems.map((item, index) => (
                  <li key={index}>
                    <Link
                      className="block py-2 text-gray-600 hover:text-orange-500"
                      to={item.link}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Dropdown Nos Sites */}
            <div>
              <button
                onClick={() => setSitesDropdownOpen(!isSitesDropdownOpen)}
                className="w-full text-left text-gray-700 text-lg hover:text-orange-500"
              >
                Nos sites ▼
              </button>
              <ul
                className={`pl-4 transition-all duration-200 ${
                  isSitesDropdownOpen ? "block" : "hidden"
                }`}
              >
                {sitesMenuItems.map((item, index) => (
                  <li key={index}>
                    <Link
                      className="block py-2 text-gray-600 hover:text-orange-500"
                      to={item.link}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Lien d'accès client ou connexion */}
            <Link
              className="block text-lg text-orange-600 hover:underline"
              to={currentUser ? DASHBOARD : LOGIN}
            >
              {currentUser ? "Accès client" : "Connexion"}
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
