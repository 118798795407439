
export const SEARCH_REQUESTING = 'SEARCH_REQUESTING'
export const SEARCH_REQUESTING_ALERTE = 'SEARCH_REQUESTING_ALERTE'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_FAILED = 'SEARCH_FAILED'
export const SEARCH_EMPTY= 'SEARCH_EMPTY'

export const SEARCH_DETAIL_REQUESTING = 'SEARCH_DETAIL_REQUESTING'
export const SEARCH_DETAIL_SUCCESS = 'SEARCH_DETAIL_SUCCESS'
export const SEARCH_DETAIL_FAILED = 'SEARCH_DETAIL_FAILED'

//Decision pour le demandeur
export const SEARCH_DECISION_APPLICANTS_REQUESTING = 'SEARCH_DECISION_APPLICANTS_REQUESTING'
export const SEARCH_DECISION_APPLICANTS_SUCCESS = 'SEARCH_DECISION_APPLICANTS_SUCCESS'
export const SEARCH_DECISION_APPLICANTS_FAILED = 'SEARCH_DECISION_APPLICANTS_FAILED'
export const PAGINATE_SEARCH_DECISION_APPLICANTS_REQUESTING = 'PAGINATE_SEARCH_DECISION_APPLICANTS_REQUESTING'
export const PAGINATE_SEARCH_DECISION_APPLICANTS_SUCCESS = 'PAGINATE_SEARCH_DECISION_APPLICANTS_SUCCESS'
export const PAGINATE_SEARCH_DECISION_APPLICANTS_FAILED = 'PAGINATE_SEARCH_DECISION_APPLICANTS_FAILED'

//Decision pour le defendeur
export const SEARCH_DECISION_DEFENDANTS_REQUESTING = 'SEARCH_DECISION_DEFENDANTS_REQUESTING'
export const SEARCH_DECISION_DEFENDANTS_SUCCESS = 'SEARCH_DECISION_DEFENDANTS_SUCCESS'
export const SEARCH_DECISION_DEFENDANTS_FAILED = 'SEARCH_DECISION_DEFENDANTS_FAILED'
export const PAGINATE_SEARCH_DECISION_DEFENDANTS_REQUESTING = 'PAGINATE_SEARCH_DECISION_DEFENDANTS_REQUESTING'
export const PAGINATE_SEARCH_DECISION_DEFENDANTS_SUCCESS = 'PAGINATE_SEARCH_DECISION_DEFENDANTS_SUCCESS'
export const PAGINATE_SEARCH_DECISION_DEFENDANTS_FAILED = 'PAGINATE_SEARCH_DECISION_DEFENDANTS_FAILED'

//Autre decision pour le demandeur
export const SEARCH_OTHERS_APPLICANTS_REQUESTING = 'SEARCH_OTHERS_APPLICANTS_REQUESTING'
export const SEARCH_OTHERS_APPLICANTS_SUCCESS = 'SEARCH_OTHERS_APPLICANTS_SUCCESS'
export const SEARCH_OTHERS_APPLICANTS_FAILED = 'SEARCH_OTHERS_APPLICANTS_FAILED'
export const PAGINATE_SEARCH_OTHERS_APPLICANTS_REQUESTING = 'PAGINATE_SEARCH_OTHERS_APPLICANTS_REQUESTING'
export const PAGINATE_SEARCH_OTHERS_APPLICANTS_SUCCESS = 'PAGINATE_SEARCH_OTHERS_APPLICANTS_SUCCESS'
export const PAGINATE_SEARCH_OTHERS_APPLICANTS_FAILED = 'PAGINATE_SEARCH_OTHERS_APPLICANTS_FAILED'

//Autre decision pour le defedant
export const SEARCH_OTHERS_DEFENDANTS_REQUESTING = 'SEARCH_OTHERS_DEFENDANTS_REQUESTING'
export const SEARCH_OTHERS_DEFENDANTS_SUCCESS = 'SEARCH_OTHERS_DEFENDANTS_SUCCESS'
export const SEARCH_OTHERS_DEFENDANTS_FAILED = 'SEARCH_OTHERS_DEFENDANTS_FAILED'
export const PAGINATE_SEARCH_OTHERS_DEFENDANTS_REQUESTING = 'PAGINATE_SEARCH_OTHERS_DEFENDANTS_REQUESTING'
export const PAGINATE_SEARCH_OTHERS_DEFENDANTS_SUCCESS = 'PAGINATE_SEARCH_OTHERS_DEFENDANTS_SUCCESS'
export const PAGINATE_SEARCH_OTHERS_DEFENDANTS_FAILED = 'PAGINATE_SEARCH_OTHERS_DEFENDANTS_FAILED'



export const SEARCH_DETAIL_DIRIGEANTS_REQUESTING = 'SEARCH_DETAIL_DIRIGEANTS_REQUESTING'
export const SEARCH_DETAIL_DIRIGEANTS_SUCCESS = 'SEARCH_DETAIL_DIRIGEANTS_SUCCESS'
export const SEARCH_DETAIL_DIRIGEANTS_FAILED = 'SEARCH_DETAIL_DIRIGEANTS_FAILED'
export const SEARCH_ADD_TO_CART = 'SEARCH_ADD_TO_CART'
export const SEARCH_REMOVE_FROM_CART = 'SEARCH_REMOVE_FROM_CART'